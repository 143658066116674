import extractByCSV from "./extractByCSV"
import extractByDateRange from "./extractByDateRange"
import extractAllDocuments from "./extractAllDocuments"
import extractByMigrationTag from "./extractByMigrationTag"

const handleExtraction = async ({
  state,
  config,
  setLoading,
  setView,
  setCurrent,
  setTotal,
  setProgress,
  errorCallback,
}) => {
  setLoading(true)
  let client = state?.client?.value || state?.client || "All"
  let migrationTag = state?.migrationTag
  let type = state?.type?.value
  let option = state?.option
  let data

  try {
    switch (option?.toLowerCase()) {
      case "migration tag":
        data = await extractByMigrationTag({
          migrationTag,
          option,
          data: state?.docData,
          type,
          config,
          setCurrent,
          setTotal,
          setProgress,
          setView,
        })
        break
      case "csv upload":
        data = await extractByCSV({
          option,
          data: state?.docData,
          type,
          config,
          setCurrent,
          setTotal,
          setProgress,
          setView,
        })
        break
      case "all":
        data = await extractAllDocuments({
          option,
          data: state?.docData,
          type,
          config,
        })
        break
      case "date range":
      default:
        data = await extractByDateRange({
          type,
          option,
          startDate: {
            month: state?.startDate?.month?.value,
            date: state?.startDate?.date?.value,
            year: state?.startDate?.year?.value,
          },
          endDate: {
            month: state?.endDate?.month?.value,
            date: state?.endDate?.date?.value,
            year: state?.endDate?.year?.value,
          },
          headerKey: state?.headerKey?.value,
          client,
          config,
          setCurrent,
          setTotal,
          setProgress,
          setView,
        })
        break
    }
    if (data.length === 0) {
      throw new Error(
        "No data found with the given filter. Please ensure that you set the right filter. You may also contact IT if the error persists."
      )
    }
    return data
  } catch (e) {
    errorCallback(e)
    setView("FORM")
  }
}

export default handleExtraction
