import React, { Fragment, useState, useEffect, memo } from "react"

import Loading from "elements/Loading"
import Datatable from "elements/Datatable"
import ActionButtons from "elements/ActionButtons"

import headersToKeys from "./utils/headersToKeys"
import groupByKey from "./utils/groupByKey"
import handleExtraction from "./services/handleExtraction"
import downloadCSV from "./services/downloadCSV"

import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import getMonthRange from "./utils/getMonthRange"
import partitionByMonths from "./services/partitionByMonths"

import { GATSBY_EXTRACT_PB_DEFAULT } from "gatsby-env-variables"
import { getValue } from "./services/parseDefaultValue"
import msToTime from "./utils/msToTime"

const ExtractorVerify = ({
  state,
  dispatch,
  setView,
  config,
  loading,
  setLoading,
  setProgress,
}) => {
  let [data, setData] = useState([])
  let [headers, setHeaders] = useState([])
  let [current, setCurrent] = useState(0)
  let [total, setTotal] = useState(0)
  let headerKeys = state?.headers.map((header) => header.value)
  let headerKey = state?.headerKey?.value || state?.headerKey

  let option = state?.option
  let client =
    option.toLowerCase() === "date range"
      ? state?.client?.value || state?.client || "All"
      : "All"
  let type = state?.type?.value

  const errorCallback = (error) => {
    dispatch({ type: "HIDE_MODAL" })
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "An error has occurred",
        isCard: true,
        headerClass: `has-text-danger has-background-info-danger has-text-weight-bold is-size-5`,
        content: (
          <Fragment>
            <p className="has-text-left mb-1">
              There was a problem when extracting the data
            </p>
            {error?.message && (
              <details id="modal-details" className="has-text-left">
                <summary className="is-size-6">See Full Details</summary>
                <p>{error?.message}</p>
              </details>
            )}
          </Fragment>
        ),
      },
    })
  }

  useEffect(() => {
    async function fetchData() {
      let start = new Date()
      let data = await handleExtraction({
        state,
        config,
        setLoading,
        setView,
        setCurrent,
        setTotal,
        setProgress,
        errorCallback,
      })
      let end = msToTime(new Date() - start)
      // if (data?.length > 0)
      //   data.forEach((x) => {
      //     delete x.ref

      //     // if (typeof value === "object") value = JSON.stringify(value)
      //   })

      let tableData =
        data && data?.length !== 0
          ? JSON.parse(JSON.stringify(data)).map((value) => {
              let newObj = Object.create(null)
              Object.keys(value).forEach(
                (key) =>
                  (newObj[key] = getValue({
                    value: value[key],
                    dataType: config[type]?.firestoreKey,
                  }).replace(/["]+/g, ""))
              )
              return newObj
            })
          : []
      let tempHeaders = []

      if (tableData.length)
        Object.keys(tableData?.[0]).forEach((key) => {
          if (!headers.includes(key)) tempHeaders.push(key)
        })

      setHeaders(tempHeaders)
      setData(data)
      setLoading(false)
      setProgress(0)

      //dispatch({ type: "HIDE_MODAL" })
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Extraction Completed",
          isCard: true,
          headerClass: `has-text-success has-background-info-success has-text-weight-bold is-size-5`,
          content: (
            <Fragment>
              <p>
                A total of {tableData.length?.toLocaleString()} rows were
                extracted in {end}.
              </p>
            </Fragment>
          ),
        },
      })
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDownload = () => {
    let convertedHeaders = headersToKeys({
      headers: headerKeys,
      firestoreKeys: config[type].firestoreKey,
    })

    if (client?.includes("All") && !GATSBY_EXTRACT_PB_DEFAULT) {
      let groupedData = { all: data } //groupByKey(data, "client")

      if (option === "Date Range") {
        let timeRange = getMonthRange({
          startDate: state?.startDate,
          endDate: state?.endDate,
        })
        groupedData = partitionByMonths({ timeRange, groupedData, headerKey })
      }

      for (const key in groupedData) {
        downloadCSV({
          data: groupedData[key],
          name: key,
          typeConfig: config[type],
          firestoreHeaders: convertedHeaders,
        })
      }
    } else {
      downloadCSV({
        data,
        name: client,
        typeConfig: config[type],
        firestoreHeaders: convertedHeaders,
      })
    }
    setLoading(false)
  }
  return (
    <Fragment>
      {data?.length === 0 && headers?.length === 0 ? (
        <Fragment>
          <div className="">
            {total !== 0 && (
              <p className="has-text-centered">
                Retrieving {current} of {total} documents
              </p>
            )}
            <Loading
              size={7}
              isFullscreen
              style={{ transform: "translateX(-23.75px)" }}
            />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="">
            <p className="ml-1">Imported data from CSV</p>
            {/* <Datatable
              options={{
                print: false,
                filter: false,
                download: false,
                selectableRows: "multiple",
              }}
              columns={headers}
              data={data}
            /> */}
          </div>
          <div className="mt-5">
            <ActionButtons
              next={{
                loading,
                callback: async () => {
                  handleDownload()
                  setLoading(false)
                  setView("FORM")
                  dispatch({ type: "RESET_DETAILS" })
                },
                label: (
                  <span>
                    <FontAwesomeIcon icon={faDownload} />
                    <span className="ml-1">Download CSV</span>
                  </span>
                ),
              }}
              back={{
                label: "Back",
                callback: () => {
                  setView("FORM")
                },
              }}
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default memo(ExtractorVerify)
