const partitionByMonths = ({ timeRange, groupedData, headerKey }) => {
  let newGroup = {}
  Object.keys(groupedData).forEach((key) => {
    timeRange.forEach((time) => {
      let temp = groupedData[key].filter((item) => {
        let date = new Date(item[headerKey])

        return (
          date.getFullYear() === time.year && date.getMonth() === time.month
        )
      })
      if (temp.length) newGroup[`${key}-${time.month + 1}-${time.year}`] = temp
    })
  })

  return newGroup
}

export default partitionByMonths
