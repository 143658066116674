import moment from "moment"

const convertTimeStampToString = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key]?.seconds)
      obj[key] = moment(obj[key]?.toDate())?.format("YYYY-MM-DD")
  })

  return obj
}

export default convertTimeStampToString
