import firebase from "firebase"
import convertTimeStampToString from "../utils/convertTimestampToString"
import timeout from "../utils/timeout"

const extractByCSV = async ({
  type,
  option,
  data,
  config,
  setCurrent,
  setTotal,
  setProgress,
}) => {
  const CHUNK_SIZE = 54

  let headerKey = Object.keys(data[0]).map((key) => key)[0]
  let firestoreKey = config[type].firestoreKey[headerKey]

  let arrayOfData = data.map((row) => String(row[headerKey]))
  let collection = config[type].collections[config[type].mainCollection]

  let reference = firebase.firestore().collection(config[type].mainCollection)
  let compiled_documents = []
  let documents

  documents = collection?.data?.includes(firestoreKey)
    ? await reference.where(firestoreKey, "in", arrayOfData).get()
    : await reference.get()

  let subcollection = collection.subcollections
    ? config[type].collections[collection.subcollections[0].name]
    : null

  let length = documents.docs.length

  setTotal(length)

  let counter = 0
  setCurrent((current) =>
    current + CHUNK_SIZE < length ? current + CHUNK_SIZE : length
  )
  setProgress((progress) => progress + (CHUNK_SIZE / length) * 100)

  for (const doc of documents.docs) {
    let baseDoc = doc.data()
    delete baseDoc._changelog
    delete baseDoc._tags
    baseDoc = convertTimeStampToString(baseDoc)

    if (subcollection) {
      let subdocs = await extractSubcollections({
        config,
        type,
        collection: subcollection,
        id: doc.id,
        reference,
        option,
        firestoreKey,
        arrayOfData,
      })
      for (let subdoc of subdocs) {
        delete subdoc._changelog
        delete subdoc._tags

        compiled_documents.push({ ...baseDoc, ...subdoc })
      }
    } else {
      compiled_documents.push(baseDoc)
    }
    if (counter % CHUNK_SIZE === 0) {
      setCurrent((current) =>
        current + CHUNK_SIZE < length ? current + CHUNK_SIZE : length
      )
      setProgress((progress) => progress + (CHUNK_SIZE / length) * 100)
      await timeout(0)
    }
    counter++
  }

  return compiled_documents
}

const extractSubcollections = async ({
  config,
  type,
  collection,
  id,
  reference,
  option,
  firestoreKey,
  arrayOfData,
}) => {
  let subcollection = collection.subcollections
    ? config[type].collections[collection.subcollections[0].name]
    : null

  reference = reference.doc(id).collection(collection.Name)
  let compiled_documents = []
  let documents

  let queryOperator =
    firestoreKey === "finalReferenceCode" ? "array-contains-any" : "in"

  documents = collection?.data?.includes(firestoreKey)
    ? await reference.where(firestoreKey, queryOperator, arrayOfData).get()
    : await reference.get()

  for (const doc of documents.docs) {
    let baseDoc = doc.data()
    delete baseDoc._changelog
    delete baseDoc._tags
    baseDoc = convertTimeStampToString(baseDoc)

    if (subcollection) {
      let subdocs = await extractSubcollections({
        config,
        type,
        collection: subcollection,
        id: doc.id,
        reference,
        option,
        firestoreKey,
        arrayOfData,
      })
      for (let subdoc of subdocs) {
        compiled_documents.push({ ...baseDoc, ...subdoc })
      }
    } else {
      compiled_documents.push(baseDoc)
    }
  }
  return compiled_documents
}

export default extractByCSV
