const filterByIncludedKeys = ({ data, firestoreKeys }) => {
  let filteredKeys = data.map((row) => {
    Object.keys(row).forEach((key) => {
      if (!firestoreKeys.includes(String(key))) delete row[key]
    })
    return row
  })
  return filteredKeys
}

export default filterByIncludedKeys
