import React, { useState, Fragment, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import Papa from "papaparse"

import ActionButtons from "elements/ActionButtons"
import Dropzone from "elements/UploadDocumentDropzone/Dropzone"
import DocumentsCardRow from "elements/UploadDocumentDropzone/DocumentsCardRow"
import ErrorBuilder from "./ErrorBuilder"
import FormSelect from "elements/Form/FormSelect"
import FormInput from "elements/Form/FormInput"
import FormStyledRadio from "elements/Form/FormStyledRadio"
import FormDate from "elements/Form/FormDate"
import Message from "elements/Message"
import Section from "elements/Section"

// import defaultHeaders from "./utils/defaultHeaders.json"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  getDateTodayString,
  getDateYesterdayString,
  isInvalidDateRange,
  getDate,
} from "./utils/dateValidation"
import { isBrowser } from "services/general"
import { useClients } from "../../context/hooks/useClients"
import { getExtractionKeyOptions } from "./utils/getExtractionKeyOptions"
import keyOptions from "./utils/options.json"
import FormListBox from "../Elements/Form/FormListbox"
import FormCheckbox from "../Elements/Form/FormCheckbox"

// import { GATSBY_EXTRACT_PB_DEFAULT } from "gatsby-env-variables"

const ExtractorForm = ({ config, state, dispatch, loading, setView }) => {
  const [csvErrors, setErrors] = useState([])
  const [keyList, setKeyList] = useState([])
  const [headerOptions, setHeaderOptions] = useState([])

  let options = Object.keys(config).map((key) => {
    return { value: key, label: key }
  })

  let allClients = useClients()
  let clientOptions = ["All", ...allClients]

  useEffect(() => {
    let firestoreKeys = config[state?.type?.value]?.firestoreKey
    setKeyList(getExtractionKeyOptions({ ...state, config }))
    if (firestoreKeys)
      setHeaderOptions(
        Object.keys(firestoreKeys).map((key) => {
          return { label: key, value: key }
        })
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.type?.value, state?.option])

  let enrollment = {}
  if (isBrowser())
    enrollment = JSON.parse(sessionStorage?.getItem("enrollment"))
  options = options?.filter((option) =>
    enrollment?.allowedDataSets?.includes(option?.value)
  )

  if (
    isBrowser() &&
    !enrollment?.allowedPaths?.includes(window?.location?.pathname)
  )
    navigate("/")

  const isFormInvalid = ({ state, values }) => {
    switch (state?.option.toLowerCase()) {
      case "csv upload":
        return state.docData.length === 0
      case "date range":
        return (
          isInvalidDateRange(values?.startDate, values?.endDate) ||
          state?.headerKey?.value === ""
        )
      case "migration tag":
      case "all":
        return false
      default:
        return true
    }
  }

  const handleUploadSuccess = async ({ file }) => {
    await dispatch({ type: "SAVE_DOCUMENT", payload: file })

    Papa.parse(file?.file, {
      header: true,
      complete: async function (results) {
        await dispatch({ type: "SAVE_DOCUMENT_DATA", payload: results.data })
      },
    })
  }

  return (
    <Fragment>
      <Message color="primary">
        <div className="content">
          <p className="is-size-6">
            In case something goes wrong, please fill out and submit an issue
            form to:{" "}
            <a
              className="has-text-weight-bold"
              target="__blank"
              href="https://mdgr.cr/IT"
            >
              mdgr.cr/IT
            </a>
          </p>
        </div>
      </Message>
      <Formik
        onSubmit={(values) => {
          dispatch({ type: "SAVE_CLIENT", payload: values?.client })
          dispatch({ type: "SAVE_START_DATE", payload: values?.startDate })
          dispatch({ type: "SAVE_END_DATE", payload: values?.endDate })
          setView("VERIFY")
        }}
        initialValues={{
          type: state?.type,
          templateHeaderKey: { key: "", value: "" },
          headers: [],
          option: state?.option,
          startDate: state?.startDate,
          endDate: state?.endDate,
          client: ["All"],
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Section
              title="Select Collection"
              subtitle="What kind of data you are extracting?"
              addOns={{ left: 1 }}
            >
              <FormSelect
                name={"type"}
                isRequired
                onChange={(value) => {
                  dispatch({ type: "SAVE_TYPE", payload: value })
                  dispatch({ type: "SAVE_HEADERS", payload: [] })

                  // if (GATSBY_EXTRACT_PB_DEFAULT) {
                  //   let collectionSelected = value?.label
                  //   let collection =
                  //     config[collectionSelected]?.collections[
                  //       config[collectionSelected]?.mainCollection
                  //     ]
                  //   let collectionBU = collection?.bu

                  //   if (collectionBU)
                  //     dispatch({
                  //       type: "SAVE_HEADERS",
                  //       payload: defaultHeaders[collectionBU]?.map(
                  //         (header) => ({
                  //           value: header,
                  //           label: header,
                  //         })
                  //       ),
                  //     })
                  // }
                }}
                options={options}
                value={state?.type}
              />
            </Section>
            <Section
              title="Select Included Headers"
              subtitle="Which headers do you want to include?"
              addOns={{
                left: 2,
              }}
            >
              <FormListBox
                options={headerOptions}
                dispatch={dispatch}
                type={"HEADERS"}
                value={state?.headers}
              />
            </Section>
            <Section
              title="Select Filter Method"
              subtitle="How would you like to filter the data?"
              addOns={{ left: 3 }}
            >
              {keyOptions.map((item, index) => {
                return (
                  <FormStyledRadio
                    size={"small"}
                    key={index}
                    isActive={item?.value === state?.option}
                    label={item.label}
                    onClick={() => {
                      dispatch({ type: "SAVE_OPTION", payload: item.value })
                      dispatch({
                        type: "SAVE_HEADER_KEY",
                        payload: { key: "", value: "" },
                      })
                    }}
                  >
                    <p className="has-text-grey">{item.subtitle}</p>
                  </FormStyledRadio>
                )
              })}
            </Section>
            {state?.option === "Date Range" && (
              <Fragment>
                <Section
                  title="Select Header Date"
                  subtitle="What template header date would you like to use for filtering?"
                  addOns={{ left: 4 }}
                >
                  <div className="mt-2 mb-4">
                    <FormSelect
                      name={"headerKey"}
                      isRequired
                      options={keyList}
                      onChange={(value) => {
                        dispatch({ type: "SAVE_HEADER_KEY", payload: value })
                      }}
                      value={state?.headerKey}
                    />
                  </div>
                </Section>
                <Section
                  title="Select Client"
                  subtitle="Which client do you want to extract?"
                  addOns={{ left: 5 }}
                >
                  <div className="mt-2 mb-4">
                    <FormCheckbox
                      name="client"
                      isRequired
                      restrictedOption={"All"}
                      setFieldValue={setFieldValue}
                      onChange={({ newValue }) => {
                        dispatch({ type: "SAVE_CLIENT", payload: newValue })
                      }}
                      options={clientOptions}
                      value={state?.client?.value || state?.client}
                    />
                  </div>
                </Section>
                <Section
                  title="Select Date Range"
                  subtitle="What range of dates do you want to extract?"
                  addOns={{ left: 6 }}
                >
                  <div className="mt-2 mb-4">
                    <FormDate
                      label="Start Date"
                      name={"startDate"}
                      onChange={(value) => {
                        dispatch({ type: "SAVE_START_DATE", payload: value })
                      }}
                      values={values?.startDate}
                      setFieldValue={setFieldValue}
                      config={{
                        past: true,
                      }}
                    />
                    <FormDate
                      label="End Date"
                      name="endDate"
                      values={values?.endDate}
                      config={{
                        past: true,
                      }}
                    />
                  </div>
                </Section>
              </Fragment>
            )}
            {state?.option === "Migration Tag" && (
              <Section title="Migration Tag" addOns={{ left: 4 }}>
                <Message color="primary">
                  <p className="is-size-6 has-text-weight-bold">
                    MIGRATION-220801-CLIENT
                  </p>
                  <p className="is-size-6">
                    Migration tags consists of date of migration and client
                    code. If you are not sure what is your client code, please
                    contact IT or submit a form at{" "}
                    <a
                      className="has-text-weight-bold"
                      target="__blank"
                      href="https://mdgr.cr/IT"
                    >
                      mdgr.cr/IT
                    </a>
                  </p>
                </Message>
                <FormInput
                  name={"migrationTag"}
                  placeholder={"MIGRATION-220801-CLIENT"}
                  isRequired
                  maxLength={150}
                  onChange={(event) => {
                    dispatch({
                      type: "SAVE_MIGRATION_TAG",
                      payload: event.target.value,
                    })
                  }}
                />
              </Section>
            )}
            {state?.option === "CSV Upload" && (
              <Section title="Upload CSV" addOns={{ left: 4 }}>
                <Message color="primary">
                  <div className="content">
                    <p className="is-size-6">
                      You may download a copy of the template{" "}
                      <a
                        href="/data/template-csv-extractor.csv"
                        download="template-csv-extractor"
                        className="mt-1"
                      >
                        here
                      </a>
                    </p>
                  </div>
                </Message>
                {state?.documents?.length > 0 ? (
                  <Fragment>
                    <div className="mt-2 mb-2">
                      {state?.documents.map((file, index) => (
                        <DocumentsCardRow
                          key={index}
                          fileName={file.name}
                          oldFileName={file.oldname}
                          index={index}
                          handleDeleteDocument={() => {
                            setErrors([])
                            dispatch({ type: "RESET_DETAILS" })
                          }}
                          file={file}
                        />
                      ))}
                    </div>
                    {csvErrors?.length > 0 && (
                      <ErrorBuilder errors={csvErrors} />
                    )}
                  </Fragment>
                ) : (
                  <Dropzone
                    docType={"csv"}
                    maxFileCount={1}
                    icon={"document"}
                    label={"Upload CSV"}
                    currentFilesList={state?.documents}
                    onUploadSuccess={handleUploadSuccess}
                  />
                )}
              </Section>
            )}
            <ActionButtons
              back={{ label: "Back", link: "/" }}
              submit={{
                loading,
                disabled: isFormInvalid({ state, values }),
                callback: () => {},
                label: (
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span className="ml-1">Verify CSV</span>
                  </span>
                ),
              }}
            />
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default ExtractorForm
