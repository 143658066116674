const getMonthRange = ({ startDate, endDate }) => {
  let parsedStartDate = parseDateObjecttoDate(startDate)
  let parsedEndDate = parseDateObjecttoDate(endDate)

  let month = parsedStartDate.getMonth()
  let year = parsedStartDate.getFullYear()

  let time = []

  while (
    month % 12 !== parsedEndDate.getMonth() + 1 ||
    year !== parsedEndDate.getFullYear()
  ) {
    time.push({ month: month % 12, year })

    month++

    if (month % 12 === 0) year++
  }

  return time
}

const parseDateObjecttoDate = (date) => {
  let month = date.month?.value || date?.month
  let day = date.date?.value || date?.date
  let year = date.year?.value || date?.year

  return new Date(`${month} ${day}, ${year}`)
}

getMonthRange({
  startDate: { month: "Jan", year: "2016" },
  endDate: { month: "Feb", year: "2022" },
})

export default getMonthRange
