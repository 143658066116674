const headersToKeys = ({ headers, firestoreKeys }) => {
  let convertedKeys = headers.flatMap((header) => {
    let keyFound = Object.keys(firestoreKeys).find((key) => key === header)
    if (keyFound) return firestoreKeys[keyFound]
  })

  return convertedKeys
}

export default headersToKeys
