import React, { useState } from "react"
import Layout from "layout"
import Extractor from "../components/Extractor"

const ExtractorPage = () => {
  const [progress, setProgress] = useState(0)

  return (
    <Layout
      seoTitle="Extractor"
      title="MG Kit Cloud Extractor"
      progress={progress}
      display={{ helpCenterBanner: false, footer: false }}
      subtitle="Filter & search to download data from the cloud"
    >
      <Extractor isPrivate setProgress={setProgress} />
    </Layout>
  )
}

export default ExtractorPage
