import Papa from "papaparse"
import filterByIncludedKeys from "../utils/filterByIncludedKeys"
import { parseHeaders, parseCSVValues } from "../services/parseDefaultValue"

import { GATSBY_EXTRACT_PB_DEFAULT } from "gatsby-env-variables"

const downloadCSV = ({ data, name, typeConfig, firestoreHeaders }) => {
  let firestoreKeys = typeConfig.firestoreKey

  let source = typeConfig.source

  let parsedData = filterByIncludedKeys({
    data,
    firestoreKeys: firestoreHeaders,
  })

  let csv = Papa.unparse({
    fields: firestoreHeaders,
    data: parsedData,
  })

  let headers = csv.split("\n")[0].replace("\r", "").split(",")
  let templateHeaders = parseTemplateHeaders(headers, firestoreKeys)
  let sourceHeaders = parseSourceHeaders(headers, source)

  // if (GATSBY_EXTRACT_PB_DEFAULT) {
  //   let parsedHeaders = parseHeaders()
  //   csv =
  //     parsedHeaders?.sourceHeaders +
  //     "\n" +
  //     parsedHeaders?.firestoreHeaders +
  //     "\n" +
  //     parsedHeaders?.templateHeaders +
  //     "\n" +
  //     parseCSVValues({ data, typeConfig })
  // }

  let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" })
  let csvURL = window.URL.createObjectURL(csvData)

  let testLink = document.createElement("a")
  testLink.href = csvURL
  testLink.setAttribute("download", `extractor-${name}.csv`)
  testLink.click()
}

const parseTemplateHeaders = (headers, firestoreKeys) => {
  let templateHeaders =
    headers.map((header) => getValByKey(firestoreKeys, header)[0]).toString() +
    "\n"
  return templateHeaders
}
const parseSourceHeaders = (headers, source) => {
  let sourcedHeaders = headers.map((header) => source[header]).toString() + "\n"
  return sourcedHeaders
}

/**
 *
 * @param {Object} obj [Object to be mapped to find the value]
 * @param {Any} value [Value to be searched in the obj]
 * @returns {Array<Number>} [index of the unique key]
 */

const getValByKey = (obj, value) => {
  return Object.keys(obj).filter(
    (key) => obj[key].toLowerCase() === value.toLowerCase()
  )
}
export default downloadCSV
