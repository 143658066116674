import firebase from "firebase"
import convertTimeStampToString from "../utils/convertTimestampToString"
import timeout from "../utils/timeout"

const extractByMigrationTag = async ({
  config,
  migrationTag,
  type,
  option,
  headerKey,
  setCurrent,
  setTotal,
  setProgress,
}) => {
  const CHUNK_SIZE = 54
  let collection = config[type].collections[config[type].mainCollection]
  let reference = firebase.firestore().collection(config[type].mainCollection)

  let compiled_documents = []
  let documents

  documents = await performQuery({
    documents,
    reference,
    migrationTag,
  })
  let length = documents.docs.length

  setTotal(length)

  let subcollection = collection.subcollections
    ? config[type].collections[collection.subcollections[0].name]
    : null

  let counter = 0
  setCurrent((current) =>
    current + CHUNK_SIZE < length ? current + CHUNK_SIZE : length
  )
  setProgress((progress) => progress + (CHUNK_SIZE / length) * 100)

  for (const doc of documents.docs) {
    let baseDoc = doc.data()
    delete baseDoc._changelog
    delete baseDoc._tags
    baseDoc = convertTimeStampToString(baseDoc)

    if (subcollection) {
      let subdocs = await extractSubcollections({
        config,
        type,
        collection: subcollection,
        id: doc.id,
        reference,
        option,
        headerKey,
        migrationTag,
      })
      for (let subdoc of subdocs) {
        compiled_documents.push({ ...baseDoc, ...subdoc })
      }
    } else {
      compiled_documents.push(baseDoc)
    }
    if (counter % CHUNK_SIZE === 0) {
      setCurrent((current) =>
        current + CHUNK_SIZE < length ? current + CHUNK_SIZE : length
      )

      setProgress((progress) => progress + (CHUNK_SIZE / length) * 100)
      await timeout(0)
    }
    counter++
  }

  return compiled_documents
}

const extractSubcollections = async ({
  config,
  type,
  collection,
  id,
  reference,
  option,
  headerKey,
  migrationTag,
}) => {
  let subcollection = collection.subcollections
    ? config[type].collections[collection.subcollections[0].name]
    : null

  reference = reference.doc(id).collection(collection.Name)
  let documents
  let compiled_documents = []

  documents = await performQuery({
    documents,
    reference,
    migrationTag,
  })

  for (const doc of documents.docs) {
    let baseDoc = doc.data()
    delete baseDoc._changelog
    delete baseDoc._tags
    baseDoc = convertTimeStampToString(baseDoc)

    if (subcollection) {
      let subdocs = await extractSubcollections({
        config,
        type,
        collection: subcollection,
        id: doc.id,
        reference,
        option,
        headerKey,
      })
      for (let subdoc of subdocs) {
        compiled_documents.push({ ...baseDoc, ...subdoc })
      }
    } else {
      compiled_documents.push(baseDoc)
    }
  }

  return compiled_documents
}

const performQuery = async ({ documents, reference, migrationTag }) => {
  documents = await reference
    .where("migrationTag", "array-contains", migrationTag)
    .get()

  return documents
}

export default extractByMigrationTag
