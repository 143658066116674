export const getExtractionKeyOptions = ({ type, option, config }) => {
  if (type.value !== "" && option !== "") {
    let headerKey =
      option === "Date Range" ? "extractionDate" : "extractionTemplateKey"

    return Object.keys(config[type?.value][headerKey]).map((key) => {
      return {
        label: key,
        value: config[type.value][headerKey][key],
      }
    })
  }
  return []
}
