import { useStaticQuery, graphql } from "gatsby"

const ALL_CLIENT_QUERY = graphql`
  query ClientQuery {
    allAirtableClients(sort: { order: ASC, fields: data___Order }) {
      nodes {
        data {
          Client
        }
      }
    }
  }
`

export const useClients = () => {
  let data = useStaticQuery(ALL_CLIENT_QUERY)

  return data?.allAirtableClients?.nodes?.map((node) => node?.data?.Client)
}
