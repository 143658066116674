import firebase from "firebase"
import convertTimeStampToString from "../utils/convertTimestampToString"

const extractAllDocuments = async ({ type, option, data, config }) => {
  let collection = config[type].collections[config[type].mainCollection]
  let reference = firebase.firestore().collection(config[type].mainCollection)
  let compiled_documents = []
  let documents

  try {
    documents = await reference.get()

    let subcollection = collection.subcollections
      ? config[type].collections[collection.subcollections[0].name]
      : null

    for (const doc of documents.docs) {
      let baseDoc = doc.data()
      delete baseDoc._changelog
      delete baseDoc._tags
      baseDoc = convertTimeStampToString(baseDoc)

      if (subcollection) {
        let subdocs = await extractSubcollections({
          config,
          type,
          collection: subcollection,
          id: doc.id,
          reference,
          option,
        })
        for (let subdoc of subdocs) {
          delete subdoc._changelog
          delete subdoc._tags

          compiled_documents.push({ ...baseDoc, ...subdoc })
        }
      } else {
        compiled_documents.push(baseDoc)
      }
    }

    return compiled_documents
  } catch (e) {}
}

const extractSubcollections = async ({
  config,
  type,
  collection,
  id,
  reference,
  option,
}) => {
  let subcollection = collection.subcollections
    ? config[type].collections[collection.subcollections[0].name]
    : null

  reference = reference.doc(id).collection(collection.Name)
  let compiled_documents = []
  let documents

  try {
    documents = await reference.get()

    for (const doc of documents.docs) {
      let baseDoc = doc.data()
      delete baseDoc._changelog
      delete baseDoc._tags
      baseDoc = convertTimeStampToString(baseDoc)

      if (subcollection) {
        let subdocs = await extractSubcollections({
          config,
          type,
          collection: subcollection,
          id: doc.id,
          reference,
          option,
        })
        for (let subdoc of subdocs) {
          compiled_documents.push({ ...baseDoc, ...subdoc })
        }
      } else {
        compiled_documents.push(baseDoc)
      }
    }
    return compiled_documents
  } catch (e) {}
}

export default extractAllDocuments
