import React, { useContext, useState } from "react"

import Container from "layout/Container"
import PrivateRoute from "../PrivateRoute"
import { AppContext } from "../../context/AppContext"
import { useConfig } from "../../context/hooks/useConfig"
import ExtractorForm from "./ExtractorForm"
import ExtractorVerify from "./ExtractorVerify"

const Extractor = ({ isPrivate, setProgress }) => {
  const [loading, setLoading] = useState(false)
  const [view, setView] = useState("FORM")
  const { state, dispatch } = useContext(AppContext)

  let config = useConfig()
  const getView = () => {
    switch (view) {
      case "VERIFY":
        return (
          <ExtractorVerify
            state={state}
            dispatch={dispatch}
            setView={setView}
            config={config}
            loading={loading}
            setLoading={setLoading}
            setProgress={setProgress}
          />
        )
      case "FORM":
      default:
        return (
          <ExtractorForm
            config={config}
            state={state}
            dispatch={dispatch}
            setView={setView}
            setLoading={setLoading}
            loading={loading}
          />
        )
    }
  }

  return (
    <PrivateRoute isPrivate={isPrivate}>
      <Container isCentered desktop={8} fullhd={7}>
        <div className="mb-3">{getView()}</div>
      </Container>
    </PrivateRoute>
  )
}

export default Extractor
