import React, { Fragment, useState, useEffect } from "react"
//import { Field, ErrorMessage, useFormikContext } from "formik"
import ReactDragListView from "react-drag-listview"
import classNames from "classnames"
import styles from "./utils/form.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "elements/Button"

import {
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faGripVertical,
} from "@fortawesome/free-solid-svg-icons"

const FormListBox = ({ value, options, dispatch, type }) => {
  const [currentValues, setCurrentValues] = useState(value)
  const [optionValues, setOptionValues] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedCurrent, setSelectedCurrent] = useState([])
  const [search, setSearch] = useState("")
  const [searchedValues, setSearchedValues] = useState([])

  const addToSelect = (e) => {
    e.preventDefault()
    setCurrentValues([...currentValues, ...selectedOptions])

    setOptionValues(
      optionValues.filter((opt) => !selectedOptions.includes(opt))
    )
    setSelectedOptions([])
  }

  const removeFromSelect = (e) => {
    e.preventDefault()
    setOptionValues([...optionValues, ...selectedCurrent])
    setCurrentValues(
      currentValues.filter((curr) => !selectedCurrent.includes(curr))
    )
    setSelectedCurrent([])
  }

  const addAllToSelect = (e) => {
    e.preventDefault()
    setCurrentValues([...currentValues, ...optionValues])
    setSelectedOptions([])
    setOptionValues([])
  }
  const removeAllFromSelect = (e) => {
    e.preventDefault()
    setOptionValues([...optionValues, ...currentValues])
    setSelectedCurrent([])
    setCurrentValues([])
  }

  useEffect(() => {
    setCurrentValues(value)
    //eslint-disable-next-line
  }, [value])

  useEffect(() => {
    dispatch({ type: `SAVE_${type}`, payload: currentValues })

    let newOptions = optionValues.filter((opt) => !currentValues.includes(opt))
    setOptionValues(newOptions)
    //eslint-disable-next-line
  }, [currentValues])

  useEffect(() => {
    if (!value.length) {
      setOptionValues(options)
      setSearch("")
    }
    setCurrentValues(value)
    //eslint-disable-next-line
  }, [options])

  useEffect(() => {
    let newOptions = optionValues.filter((opt) =>
      opt.value.toLowerCase().includes(search.toLowerCase())
    )
    setSearchedValues(newOptions)
    //eslint-disable-next-line
  }, [search, currentValues])

  const dragPropsSelected = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...optionValues]
      const item = data.splice(fromIndex, 1)[0]
      data.splice(toIndex, 0, item)
      setOptionValues([...data])
    },
    nodeSelector: "button",
    handleSelector: "button",
  }

  const dragPropsCurrent = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...currentValues]
      const item = data.splice(fromIndex, 1)[0]
      data.splice(toIndex, 0, item)
      setCurrentValues([...data])
    },
    nodeSelector: "button",
    handleSelector: "button",
  }

  return (
    <Fragment>
      <div className="columns mt-1 mb-1 mx-0">
        <div
          className={classNames(
            "column is-5 select is-multiple is-flex is-flex-direction-column is-justify-content-space-between",
            styles["formListboxBox"]
          )}
        >
          <p className="mb-0">Options</p>
          <div>
            <input
              type={"search"}
              className="input mb-1"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search for an option"
              value={search}
            />

            <ReactDragListView {...dragPropsSelected}>
              <div className={styles["formListboxBox__option"]}>
                {(search === "" ? optionValues : searchedValues).map(
                  (item, index) => (
                    <button
                      key={index}
                      className={classNames(
                        "has-text-left	",
                        selectedOptions.includes(item) ? "is-light" : "is-white"
                      )}
                      onClick={(e) => {
                        e.preventDefault()
                        !selectedOptions.includes(item)
                          ? setSelectedOptions([...selectedOptions, item])
                          : setSelectedOptions(
                              selectedOptions.filter((opt) => opt !== item)
                            )
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faGripVertical}
                        className="mr-1 has-text-primary"
                      />

                      {item.value}
                    </button>
                  )
                )}
              </div>
            </ReactDragListView>
          </div>
        </div>
        <div
          className={classNames(
            "column is-2 px-1 is-flex is-flex-direction-column is-justify-content-space-evenly has-text-grey"
          )}
        >
          <Button
            className="has-text-grey-light my-1"
            onClick={removeAllFromSelect}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </Button>
          <Button className="has-text-grey-light	" onClick={removeFromSelect}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
          <Button className="has-text-grey-light	 my-1" onClick={addToSelect}>
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
          <Button className="has-text-grey-light	" onClick={addAllToSelect}>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </Button>
        </div>
        <div
          className={classNames(
            "column is-5 select is-multiple is-flex is-flex-direction-column is-justify-content-flex-end",
            styles["formListboxBox"]
          )}
        >
          <p className="mb-0">Selected</p>
          <ReactDragListView {...dragPropsCurrent}>
            <div className="">
              {currentValues.map((item, index) => (
                <button
                  key={index}
                  className={classNames(
                    "has-text-left",
                    selectedCurrent.includes(item) ? "is-light" : "is-white"
                  )}
                  onClick={(e) => {
                    e.preventDefault()
                    !selectedCurrent.includes(item)
                      ? setSelectedCurrent([...selectedCurrent, item])
                      : setSelectedCurrent(
                          selectedCurrent.filter((opt) => opt !== item)
                        )
                  }}
                >
                  <FontAwesomeIcon
                    icon={faGripVertical}
                    className="mr-1 has-text-primary"
                  />
                  {item.value}
                </button>
              ))}
            </div>
          </ReactDragListView>
        </div>
      </div>
    </Fragment>
  )
}

export default FormListBox
