let today = new Date()
let yesterday = new Date()
yesterday.setDate(today.getDate() - 1)

export const getDate = ({ month, year, date }) => {
  month = month?.value || month
  year = year?.value || year
  date = date?.value || date

  let dateString = `${year}-${
    new Date(Date.parse(month + " 1, 2021")).getMonth() + 1
  }-${date}`
  return dateString
}

export const getDateTodayString = () =>
  getDate({
    month: today.getMonth() + 1,
    year: today.getFullYear(),
    date: today.getDate(),
  })

export const getDateYesterdayString = () =>
  getDate({
    month: yesterday.getMonth() + 1,
    year: yesterday.getFullYear(),
    date: yesterday.getDate(),
  })

export const isInvalidDateRange = (startDate, endDate) => {
  let parsedStartDate =
    startDate.date.value > 0
      ? new Date(
          Date.parse(
            `${startDate.month.value} ${startDate.date.value}, ${startDate.year.value}`
          )
        )
      : today
  let parsedEndDate =
    endDate.date.value > 0
      ? new Date(
          Date.parse(
            `${endDate.month.value} ${endDate.date.value}, ${endDate.year.value}`
          )
        )
      : new Date(Date.parse("Jan 1, 1999"))
  return parsedEndDate < parsedStartDate
}
