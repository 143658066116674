import firebase from "firebase"
import convertTimeStampToString from "../utils/convertTimestampToString"
import timeout from "../utils/timeout"

const extractByDateRange = async ({
  config,
  type,
  option,
  startDate,
  endDate,
  headerKey,
  client = "All",
  setCurrent,
  setTotal,
  setProgress,
}) => {
  const CHUNK_SIZE = 54
  let parsedStartDate = new Date(
    `${startDate.month} ${startDate.date}, ${startDate.year}`
  )
  let parsedEndDate = new Date(
    `${endDate.month} ${endDate.date}, ${endDate.year}`
  )
  let collection = config[type].collections[config[type].mainCollection]
  let reference = firebase.firestore().collection(config[type].mainCollection)

  let compiled_documents = []
  let documents
  documents = await performQuery({
    documents,
    reference,
    client,
    collection,
    parsedStartDate,
    parsedEndDate,
    headerKey,
  })
  let length = documents.docs.length

  setTotal(length)

  let subcollection = collection.subcollections
    ? config[type].collections[collection.subcollections[0].name]
    : null

  let counter = 0
  setCurrent((current) =>
    current + CHUNK_SIZE < length ? current + CHUNK_SIZE : length
  )
  setProgress((progress) => progress + (CHUNK_SIZE / length) * 100)

  for (const doc of documents.docs) {
    let baseDoc = doc.data()
    delete baseDoc._changelog
    delete baseDoc._tags
    baseDoc = convertTimeStampToString(baseDoc)

    if (subcollection) {
      let subdocs = await extractSubcollections({
        config,
        type,
        collection: subcollection,
        id: doc.id,
        reference,
        option,
        parsedStartDate,
        parsedEndDate,
        headerKey,
      })
      for (let subdoc of subdocs) {
        compiled_documents.push({ ...baseDoc, ...subdoc })
      }
    } else {
      compiled_documents.push(baseDoc)
    }
    if (counter % CHUNK_SIZE === 0) {
      setCurrent((current) =>
        current + CHUNK_SIZE < length ? current + CHUNK_SIZE : length
      )

      setProgress((progress) => progress + (CHUNK_SIZE / length) * 100)
      await timeout(0)
    }
    counter++
  }

  return compiled_documents
}

const extractSubcollections = async ({
  config,
  type,
  collection,
  id,
  reference,
  option,
  parsedStartDate,
  parsedEndDate,
  headerKey,
}) => {
  let subcollection = collection.subcollections
    ? config[type].collections[collection.subcollections[0].name]
    : null

  reference = reference.doc(id).collection(collection.Name)
  let documents
  let compiled_documents = []

  documents = await performQuery({
    documents,
    reference,
    client: "All",
    collection,
    parsedStartDate,
    parsedEndDate,
    headerKey,
  })

  for (const doc of documents.docs) {
    let baseDoc = doc.data()
    delete baseDoc._changelog
    delete baseDoc._tags
    baseDoc = convertTimeStampToString(baseDoc)

    if (subcollection) {
      let subdocs = await extractSubcollections({
        config,
        type,
        collection: subcollection,
        id: doc.id,
        reference,
        option,
        parsedStartDate,
        parsedEndDate,
        headerKey,
      })
      for (let subdoc of subdocs) {
        compiled_documents.push({ ...baseDoc, ...subdoc })
      }
    } else {
      compiled_documents.push(baseDoc)
    }
  }

  return compiled_documents
}

const performQuery = async ({
  documents,
  reference,
  client,
  collection,
  parsedStartDate,
  parsedEndDate,
  headerKey,
}) => {
  switch (true) {
    case !client?.includes("All") && !collection?.data?.includes(headerKey):
      documents = await reference.where("client", "in", client).get()
      break
    case !client?.includes("All") && collection?.data?.includes(headerKey):
      documents = await reference
        .where("client", "in", client)
        .where(headerKey, ">=", parsedStartDate)
        .where(headerKey, "<=", parsedEndDate)
        .get()
      break
    case client?.includes("All") && collection?.data?.includes(headerKey):
      documents = await reference
        .where(headerKey, ">=", parsedStartDate)
        .where(headerKey, "<=", parsedEndDate)
        .get()
      break
    case client?.includes("All"):
    default:
      documents = await reference.get()
  }
  return documents
}

export default extractByDateRange
