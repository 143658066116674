import React from "react"
import classNames from "classnames"
import Img from "gatsby-image"

import styles from "../utils/elements.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const FormStyledRadio = ({
  isActive,
  onClick,
  label,
  logo,
  icon,
  children,
  showRadio = true,
  centerContent = false,
}) => {
  return (
    <div
      className={classNames(
        styles[`styledRadio__option${isActive ? "Active" : ""}`]
      )}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
    >
      <div
        className={classNames(`columns is-vcentered is-mobile ml-0-mobile`, {
          "is-flex-grow-1": centerContent,
          "ml-0": !centerContent,
        })}
      >
        {showRadio && (
          <div className={classNames(styles["styledRadio__radioButton"])}>
            {isActive && (
              <div
                className={classNames(
                  styles["styledRadio__radioButtonChecked"]
                )}
              ></div>
            )}
          </div>
        )}
        <div className="column">
          <div
            className={classNames(
              "is-flex  is-align-items-center is-flex-direction-column is-flex-wrap mt-1-mobile",
              styles["styledRadio__label"]
            )}
          >
            {logo && <Img fixed={logo} />}
            {icon && (
              <span className="mt-1 has-text-primary is-size-2">
                <FontAwesomeIcon icon={icon} />
              </span>
            )}
            <div className={`column ${showRadio ? "pl-1" : ""}`}>
              {label && (
                <p
                  className={`is-size-5 ${
                    centerContent && "has-text-centered"
                  }`}
                >
                  {label}
                </p>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormStyledRadio
