import headers from "../../Archiver/utils/headers.json"

const CLIENTS = ["sitel", "accenture", "viatris", "maxicare"]
const REFILL_HEADERS = [
  "dailyIntake",
  "refillTier",
  "isFirstTimeRequestingDrug",
  "isDrugInPrescription",
  "isEligible",
]

export const parseHeaders = () => {
  let sourceHeaders = headers["sourceHeaders"]?.join(",")
  let firestoreHeaders = headers["firestoreHeaders"]?.join(",")
  let templateHeaders = headers["templateHeaders"]?.join(",")

  return { sourceHeaders, firestoreHeaders, templateHeaders }
}

export const parseCSVValues = ({ data, typeConfig }) => {
  let extractorHeaders = headers["extractorHeaders"]

  let csvRows = []

  if (!!data)
    data.forEach((row) => {
      let dataToAppend = []
      extractorHeaders.forEach((header) => {
        dataToAppend.push(
          getValue({
            row,
            header,
            value: row[header],
            client: row["client"],
            quantity: row["quantity"],
            orderType: row["orderType"],
            finalAddress: row["finalAddress"],
            finalDrugName: row["finalDrugName"],
            dataType: typeConfig?.dataTypes?.[header],
          })
        )
      })

      if (row.documentType === "order")
        for (let counter = 102; counter < 137; counter++) {
          dataToAppend[counter] = "N/A"
        }

      dataToAppend = dataToAppend?.join(",")
      csvRows.push(dataToAppend)
    })

  return csvRows?.join("\n")
}

export const getValue = ({
  row,
  value,
  header,
  client,
  dataType,
  quantity,
  orderType,
  finalAddress,
  finalDrugName,
}) => {
  if (header === "zendeskId" && value?.includes("W"))
    value = value?.substring(1)

  let dataClient = client?.toLowerCase()
  if (header === "finalQuantity" && !CLIENTS?.includes(dataClient))
    value = quantity

  if (header === "drug" && !value) value = finalDrugName
  if (header === "deliveryAddress" && !value) value = finalAddress

  if (header === "refillTier" && !value && !CLIENTS?.includes(dataClient))
    return "N/A"

  if (header === "skuTitle" && orderType === "ME Refills") return row?.sku

  if (REFILL_HEADERS?.includes(header) && orderType === "ME Refills" && !value)
    return "N/A"

  if (!value || value === "Invalid date")
    switch (dataType) {
      case "integerValue":
        return 0
      case "booleanValue":
        return false
      default:
      case "timestampValue":
      case "stringValue":
        return "N/A"
    }

  if (typeof value === "string")
    return `"${value?.replace(/["]/g, '""')?.trim?.()?.replace(/  +/g, " ")}"`

  return `"${value}"`
}
